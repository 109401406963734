import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';

const init = () => {
  if (!document.querySelector('.swiper')) {
    return;
  }

  new Swiper('.swiper', {
    modules: [Navigation, Pagination, Autoplay],
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    spaceBetween: 5,
    centeredSlides: true,
    slidesPerView: 1,
    initialSlide: 0,
    speed: 580,
    loop: true,
    // Disable preloading of all images
    preloadImages: false,
    // Enable lazy loading
    // lazy: true,
    autoplay: {
     delay: 4000,
     disableOnInteraction: true
   }
 });

};

export default {init};